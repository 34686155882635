import { NavLink } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { useEffect, useState } from "react";
import _ from "lodash";
import { useFetchQAQuery } from "../../services/api";
import ErrorComponent from "../Common/Error";

const QuestionsAccordion = ({ data = [], expandAll = false }) => {
    const [openKeys, setOpenKeys] = useState([]);

    useEffect(() => {
        if (expandAll) {
            setOpenKeys(_.map(data, (item, index) => index));
        } else {
            setOpenKeys([]);
        }
    }, [expandAll]);

    return (
        <Accordion
            defaultActiveKey={[...openKeys]}
            activeKey={[...openKeys]}
            alwaysOpen
            onSelect={(e) => setOpenKeys([...e])}
        >
            {data &&
                !_.isEmpty(data) &&
                data.map((item, index) => {
                    return (
                        <Accordion.Item key={index} eventKey={index}>
                            <Accordion.Header>{item.question}</Accordion.Header>
                            <Accordion.Body>
                                <div className="d-flex flex-column">
                                    <p
                                        style={{ lineHeight: 2 }}
                                        dangerouslySetInnerHTML={{
                                            __html: item.answer.replace(
                                                /\n/g,
                                                "</br>"
                                            )
                                        }}
                                    ></p>
                                    <div className="d-flex">
                                        <span className="fw-semibold">
                                            Metadata:
                                        </span>
                                        <span className="ms-3">
                                            {item.metadata}
                                        </span>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    );
                })}
        </Accordion>
    );
};

const ManageDataComponent = () => {
    const { data, isLoading, isError, error } = useFetchQAQuery({});
    const [expand, setExpand] = useState(false);

    if (isError) {
        return <ErrorComponent message={error?.data?.error || ""} />;
    }

    return (
        <div className="row my-5 maange-data px-md-4">
            <div className="d-flex justify-content-between">
                <h4 className="fw-bold">Current Data</h4>
                <div className="d-flex align-self-center align-items-center">
                    {!isLoading && !isError && !_.isEmpty(data) && (
                        <button
                            onClick={() => setExpand((e) => !e)}
                            className="btn btn-transparent mx-3 fs-14 py-2 fw-medium"
                        >
                            {expand ? "Collapse" : "Expand"} All
                        </button>
                    )}
                    <NavLink
                        style={isLoading ? { pointerEvents: "none" } : null}
                        to={"/manage/upload"}
                        type={"button"}
                        className={
                            "btn btn-primary text-white px-4 py-2 fs-14 fw-medium"
                        }
                    >
                        Update
                    </NavLink>
                </div>
            </div>
            <div className="my-4 py-2">
                {isLoading && "Loading data.."}
                {(!data || _.isEmpty(data)) && !isLoading ? (
                    <div className="manage-data__empty my-5 py-5">
                        <div className="d-flex flex-column align-items-center">
                            <img
                                src={"/assets/no_data.svg"}
                                alt={"No Data Found"}
                                className={"img-fluid"}
                                width={100}
                            />
                            <p className="mt-3 fw-medium">No Existing Data</p>
                            <NavLink
                                to={"/manage/upload"}
                                type={"button"}
                                className="btn btn-secondary px-4"
                            >
                                Upload
                            </NavLink>
                        </div>
                    </div>
                ) : (
                    <QuestionsAccordion data={data} expandAll={expand} />
                )}
            </div>
        </div>
    );
};

export default ManageDataComponent;
