import BarChart from "./BarChart";

const DashboardStatsComponent = ({
    averageInfo = {},
    dailySessionInfo = []
}) => {
    return (
        <div className="row mt-4">
            <div className="col-md-4 col-12 order-md-1 order-2 h-md-100 mb-3 mb-md-0">
                <div className="card home-top-card h-100">
                    <div className="card-body">
                        <div className="card-title fw-semibold">
                            Average Stats
                        </div>
                        <ul className="list-unstyled stats-list mt-3">
                            <li>
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <div className="d-flex align-items-center me-5">
                                        <img
                                            src={`/assets/icons/sessions-per-day.png`}
                                            alt={"Icon 1"}
                                            className={"img-fluid w-30px me-3"}
                                        />
                                        <div className="me-2">
                                            <div className="text-gray-800 fw-semibold text-hover-primary fs-14">
                                                Sessions Per Day
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-inline-flex">
                                        <span className="me-4 fw-semibold text-gray-800">
                                            {averageInfo?.sessionsPerDay
                                                ?.count || ""}
                                        </span>
                                        <span
                                            className={`badge badge-light-${
                                                averageInfo?.sessionsPerDay
                                                    ?.deltaPercentage &&
                                                averageInfo?.sessionsPerDay
                                                    ?.deltaPercentage >= 0
                                                    ? "success"
                                                    : "danger"
                                            } fs-12`}
                                        >
                                            {averageInfo?.sessionsPerDay
                                                ?.deltaPercentage
                                                ? `${
                                                      averageInfo
                                                          ?.sessionsPerDay
                                                          ?.deltaPercentage >= 0
                                                          ? "+"
                                                          : ""
                                                  }${
                                                      averageInfo
                                                          ?.sessionsPerDay
                                                          ?.deltaPercentage
                                                  }%`
                                                : ""}
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <div className="d-flex align-items-center me-5">
                                        <img
                                            src={`/assets/icons/time-per-session.png`}
                                            alt={"Icon 1"}
                                            className={"img-fluid w-30px me-3"}
                                        />
                                        <div className="me-2">
                                            <div className="text-gray-800 fw-semibold text-hover-primary fs-14">
                                                Session Time (mins)
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-inline-flex">
                                        <span className="me-4 fw-semibold text-gray-800">
                                            {averageInfo?.timePerSession
                                                ?.count || ""}
                                        </span>
                                        <span
                                            className={`badge badge-light-${
                                                averageInfo?.timePerSession
                                                    ?.deltaPercentage &&
                                                averageInfo?.timePerSession
                                                    ?.deltaPercentage >= 0
                                                    ? "success"
                                                    : "danger"
                                            } fs-12`}
                                        >
                                            {averageInfo?.timePerSession
                                                ?.deltaPercentage
                                                ? `${
                                                      averageInfo
                                                          ?.timePerSession
                                                          ?.deltaPercentage >= 0
                                                          ? "+"
                                                          : ""
                                                  }${
                                                      averageInfo
                                                          ?.timePerSession
                                                          ?.deltaPercentage
                                                  }%`
                                                : ""}
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <div className="d-flex align-items-center me-5">
                                        <img
                                            src={`/assets/icons/queries-per-session.png`}
                                            alt={"Icon 1"}
                                            className={"img-fluid w-30px me-3"}
                                        />
                                        <div className="me-2">
                                            <div className="text-gray-800 fw-semibold text-hover-primary fs-14">
                                                Queries Per Session
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-inline-flex">
                                        <span className="me-4 fw-semibold text-gray-800">
                                            {averageInfo?.queriesPerSesions
                                                ?.count || ""}
                                        </span>
                                        <span
                                            className={`badge badge-light-${
                                                averageInfo?.queriesPerSesions
                                                    ?.deltaPercentage &&
                                                averageInfo?.queriesPerSesions
                                                    ?.deltaPercentage >= 0
                                                    ? "success"
                                                    : "danger"
                                            } fs-12`}
                                        >
                                            {averageInfo?.queriesPerSesions
                                                ?.deltaPercentage
                                                ? `${
                                                      averageInfo
                                                          ?.queriesPerSesions
                                                          ?.deltaPercentage >= 0
                                                          ? "+"
                                                          : ""
                                                  }${
                                                      averageInfo
                                                          ?.queriesPerSesions
                                                          ?.deltaPercentage
                                                  }%`
                                                : ""}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-md-8 col-12 order-md-2 order-1 mb-3 mb-md-0">
                <div className="card home-top-card">
                    <div className="card-body">
                        <div className="card-title fw-semibold">
                            Daily Interactions
                        </div>
                        <div className="d-flex mt-4">
                            <BarChart data={dailySessionInfo} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardStatsComponent;
