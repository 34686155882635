const ErrorComponent = ({
    message = "Some error occurred, please try again"
}) => {
    return (
        <div className="card home-card-top mt-5 p-4">
            <div className="alert alert-danger mb-0" role="alert">
                {message}
            </div>
        </div>
    );
};

export default ErrorComponent;
