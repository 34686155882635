import { Fragment, useState } from "react";
import _ from "lodash";
import { useGetChatSessionsQuery } from "../../services/api";
import ChatModal from "./Common/ChatModal";
import ReactPaginate from "react-paginate";

const ChatSessionsComponent = () => {
    const [page, setPage] = useState(1);
    const [lastCreatedAt, setLastCreatedAt] = useState(null);
    const { isLoading, data, isError } = useGetChatSessionsQuery({
        pageNumber: page
        // createdAtFirstItem: lastCreatedAt
    });
    const [modalContent, setModalContent] = useState({
        time: null,
        id: null
    });
    const pageLimit = 5;
    const totalCount = data?.count ? Math.ceil(data?.count / pageLimit) : 0;

    const openChatSession = (e, item) => {
        setModalContent({
            time: item.time,
            id: item.id
        });
    };

    const handlePagination = (c) => {
        setPage(c.selected + 1);
        setLastCreatedAt(
            c.selected == 0
                ? ""
                : data.chatSessionsList[data.chatSessionsList.length - 1]
                      .createdAt
        );
    };

    return (
        <Fragment>
            <div className="card home-top-card h-100">
                <div className="card-body">
                    <div className="card-title fw-semibold">Chat Sessions</div>
                    {isLoading && "Loading.."}
                    {isError && (
                        <div className="alert alert-danger" role="alert">
                            Some error occurred while loading the chat sessions,
                            please refresh and try again.
                        </div>
                    )}
                    <ul className="list-unstyled stats-list">
                        {data &&
                            data.chatSessionsList &&
                            _.map(data.chatSessionsList, (item) => {
                                return (
                                    <li key={item.id}>
                                        <div className="d-flex flex-column">
                                            <span>
                                                {item.firstMessageContent}
                                            </span>
                                            <span className="text-muted text-sm mt-1">
                                                <small>
                                                    {new Date(item.createdAt)
                                                        .toLocaleString(
                                                            "en-IN",
                                                            {
                                                                hour12: true,
                                                                dateStyle:
                                                                    "long",
                                                                timeStyle:
                                                                    "short"
                                                            }
                                                        )
                                                        .replace(" at", ",")}
                                                </small>
                                            </span>
                                        </div>
                                        <button
                                            className="btn align-self-center btn-primary fw-semibold text-white btn-sm px-4"
                                            onClick={(e) =>
                                                openChatSession(e, {
                                                    time: new Date(
                                                        item.createdAt
                                                    )
                                                        .toLocaleString(
                                                            "en-IN",
                                                            {
                                                                hour12: true,
                                                                dateStyle:
                                                                    "long",
                                                                timeStyle:
                                                                    "short"
                                                            }
                                                        )
                                                        .replace(" at", ","),
                                                    id: item.id
                                                })
                                            }
                                        >
                                            View
                                        </button>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
                {!isLoading && !isError && totalCount > 1 && (
                    <div className="card-footer bg-white py-3 d-none">
                        <ul className="pagination mb-0 justify-content-end">
                            {page > 3 && (
                                <li
                                    className={`page-item ${
                                        page == 1 && "disabled"
                                    }`}
                                >
                                    <a
                                        className="page-link"
                                        onClick={(e) =>
                                            handlePagination(page - 1)
                                        }
                                    >
                                        {"<"}
                                    </a>
                                </li>
                            )}
                            {[1, 2, 3].indexOf(page) > -1
                                ? _.times(3, (index) => {
                                      return (
                                          <li
                                              key={index}
                                              className={`page-item ${
                                                  index + 1 === page && "active"
                                              }`}
                                              aria-current="page"
                                          >
                                              {index + 1 == page ? (
                                                  <span className="page-link">
                                                      {page}
                                                  </span>
                                              ) : (
                                                  <a
                                                      className="page-link"
                                                      onClick={() =>
                                                          handlePagination(
                                                              index + 1
                                                          )
                                                      }
                                                  >
                                                      {index + 1}
                                                  </a>
                                              )}
                                          </li>
                                      );
                                  })
                                : _.times(3)
                                      .reverse()
                                      .map((index) => {
                                          return (
                                              <li
                                                  key={index}
                                                  className={`page-item ${
                                                      index === 0 && "active"
                                                  }`}
                                                  aria-current="page"
                                              >
                                                  {index == 0 ? (
                                                      <span className="page-link">
                                                          {page}
                                                      </span>
                                                  ) : (
                                                      <a
                                                          className="page-link"
                                                          onClick={() =>
                                                              handlePagination(
                                                                  page - index
                                                              )
                                                          }
                                                      >
                                                          {page - index}
                                                      </a>
                                                  )}
                                              </li>
                                          );
                                      })}
                            {page != totalCount && (
                                <li className="page-item">
                                    <a
                                        className="page-link"
                                        onClick={() =>
                                            handlePagination(page + 1)
                                        }
                                    >
                                        {">"}
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
                {!isLoading && !isError && totalCount > 1 && (
                    <div className="card-footer bg-white py-3">
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={
                                <img
                                    width={12}
                                    style={{ transform: "rotate(180deg)" }}
                                    src={
                                        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6' fill='var%28--bs-gray-700%29'%3e%3cpath d='M2.72011 2.76429L4.46358 1.02083C4.63618 0.848244 4.63617 0.568419 4.46358 0.395831C4.29099 0.223244 4.01118 0.223244 3.83861 0.395831L1.52904 2.70537C1.36629 2.86808 1.36629 3.13191 1.52904 3.29462L3.83861 5.60419C4.01117 5.77675 4.29099 5.77675 4.46358 5.60419C4.63617 5.43156 4.63617 5.15175 4.46358 4.97919L2.72011 3.23571C2.58994 3.10554 2.58994 2.89446 2.72011 2.76429Z'/%3e%3c/svg%3e"
                                    }
                                />
                            }
                            onPageChange={handlePagination}
                            pageRangeDisplayed={2}
                            pageCount={totalCount}
                            previousLabel={
                                <img
                                    width={12}
                                    src={
                                        "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6' fill='var%28--bs-gray-700%29'%3e%3cpath d='M2.72011 2.76429L4.46358 1.02083C4.63618 0.848244 4.63617 0.568419 4.46358 0.395831C4.29099 0.223244 4.01118 0.223244 3.83861 0.395831L1.52904 2.70537C1.36629 2.86808 1.36629 3.13191 1.52904 3.29462L3.83861 5.60419C4.01117 5.77675 4.29099 5.77675 4.46358 5.60419C4.63617 5.43156 4.63617 5.15175 4.46358 4.97919L2.72011 3.23571C2.58994 3.10554 2.58994 2.89446 2.72011 2.76429Z'/%3e%3c/svg%3e"
                                    }
                                />
                            }
                            renderOnZeroPageCount={null}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            containerClassName={
                                "pagination mb-0 justify-content-end"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            activeClassName={"active"}
                        />
                    </div>
                )}
            </div>
            {modalContent.id && (
                <ChatModal
                    {...modalContent}
                    closeModal={() =>
                        setModalContent({
                            time: null,
                            id: null
                        })
                    }
                />
            )}
        </Fragment>
    );
};

export default ChatSessionsComponent;
