import { AuthContextProvider } from "./context/auth-context";
import BaseRoutes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import thunkMiddleware from "redux-thunk";
import { DashboardAPI } from "./services/api";

const store = configureStore({
    reducer: {
        [DashboardAPI.reducerPath]: DashboardAPI.reducer
    },
    middleware: [thunkMiddleware, DashboardAPI.middleware]
});

setupListeners(store.dispatch);

function App() {
    return (
        <AuthContextProvider>
            <Provider store={store}>
                <BaseRoutes />
                <ToastContainer />
            </Provider>
        </AuthContextProvider>
    );
}

export default App;
