import AuthComponent from "../components/Auth";
import { useAuthContext } from "../context/auth-context";
import DashboardIndex from "./dashboard";

const BaseComponent = () => {
    const authCtx = useAuthContext();

    return authCtx.isLoggedIn ? <DashboardIndex /> : <AuthComponent />;
};

export default BaseComponent;
