import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthContext } from "../../context/auth-context";
import {
    useAddQAMutation,
    useFetchTemplateFileQuery
} from "../../services/api";
import FileUpload from "./Common/FileUpload";

const QnAComponent = () => {
    const authCtx = useAuthContext();
    const navigate = useNavigate();
    const [AddQNA] = useAddQAMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [payload, setPayload] = useState([
        {
            question: "",
            answer: ""
        }
    ]);

    // operation = 0, remove
    const AddRemoveOperation = (operation = 1, index) => {
        if (isLoading) return;

        if (operation === 1) {
            setPayload([...payload, { question: "", answer: "" }]);
        } else {
            const existingPayload = [...payload];
            existingPayload.splice(index, 1);
            setPayload([...existingPayload]);
        }
    };

    const handleFormInput = (e, index) => {
        const {
            target: { name, value }
        } = e;
        const existingPayload = [...payload];
        existingPayload[index] = {
            ...existingPayload[index],
            [name]: value
        };

        setPayload([...existingPayload]);
    };

    const handleFormSubmission = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            authCtx.toggleLoader("Processing..", true);
            const response = await AddQNA(payload).unwrap();
            setIsLoading(false);
            setPayload([
                {
                    question: "",
                    answer: ""
                }
            ]);
            toast.success("Questions added successfully");
            navigate("/manage");
        } catch (error) {
            toast.error(
                "Some error occurred while adding questions, please try again"
            );
        } finally {
            authCtx.toggleLoader("", false);
        }
    };

    return (
        <div className="form my-3">
            <form className="qna-Form" onSubmit={handleFormSubmission}>
                {payload.map((formItem, index) => {
                    return (
                        <div key={index} className="card home-top-card sm mb-3">
                            <div className="card-body">
                                <div className="mb-3">
                                    <label
                                        className="form-label"
                                        htmlFor={`question-${index + 1}`}
                                    >
                                        Question {index + 1}{" "}
                                    </label>
                                    <input
                                        name={`question`}
                                        value={formItem["question"] || ""}
                                        onChange={(e) =>
                                            handleFormInput(e, index)
                                        }
                                        placeholder={"Enter Question"}
                                        className={"form-control"}
                                        required
                                        disabled={isLoading}
                                    />
                                </div>
                                <div>
                                    <label
                                        className="form-label"
                                        htmlFor={`answer-${index + 1}`}
                                    >
                                        {/* Answer {index + 1}:{" "} */}
                                        Answer
                                    </label>
                                    <textarea
                                        name={`answer`}
                                        onChange={(e) =>
                                            handleFormInput(e, index)
                                        }
                                        placeholder={"Enter Answer"}
                                        className={"form-control"}
                                        required
                                        disabled={isLoading}
                                        rows={2}
                                        value={formItem["answer"] || ""}
                                    />
                                </div>
                                <div className="mt-3">
                                    <label
                                        className="form-label"
                                        htmlFor={`metadata-${index + 1}`}
                                    >
                                        {/* Metadata {index + 1}:{" "} */}
                                        Metadata
                                    </label>
                                    <input
                                        name={`metadata`}
                                        value={formItem["metadata"] || ""}
                                        onChange={(e) =>
                                            handleFormInput(e, index)
                                        }
                                        placeholder={"Enter Metadata"}
                                        className={"form-control"}
                                        required
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>
                            {payload.length > 1 && (
                                <i
                                    className="remove-icon"
                                    onClick={(e) =>
                                        AddRemoveOperation(0, index)
                                    }
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="#ff2825"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                        />
                                        <path d="M4 7l16 0" />
                                        <path d="M10 11l0 6" />
                                        <path d="M14 11l0 6" />
                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                    </svg>
                                </i>
                            )}
                        </div>
                    );
                })}
                <div className="d-flex add-more">
                    <button
                        type={"button"}
                        className="btn btn-block btn-dashed"
                        onClick={(e) => AddRemoveOperation(1)}
                    >
                        <span className="me-2">Add More</span>
                        <span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#9e9e9e"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <path d="M12 5l0 14" />
                                <path d="M5 12l14 0" />
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="d-flex w-100 flex-row-reverse mt-3">
                    <button
                        type={"submit"}
                        disabled={isLoading}
                        className={
                            "btn btn-primary px-4 text-white fw-semibold py-2"
                        }
                    >
                        {isLoading ? "Saving.." : "Save"}
                    </button>
                </div>
            </form>
        </div>
    );
};

const UploadDataComponent = () => {
    const authCtx = useAuthContext();
    const navigate = useNavigate();
    const [downloadFile, setDownloadFile] = useState(false);
    const {
        isLoading: isDownloading,
        isError: isDownloadError,
        isUninitialized,
        error: DownloadErrorData
    } = useFetchTemplateFileQuery({}, { skip: !downloadFile });

    const downloadTemplateFile = (e) => {
        e.preventDefault();
        setDownloadFile(true);
        authCtx.toggleLoader("Downloading..", true);
    };

    useEffect(() => {
        if (!isDownloading && !isUninitialized) {
            authCtx.toggleLoader("", false);
            setDownloadFile(false);

            // if (!isDownloadError) {
            // navigate("/manage");
            // }
        }
    }, [isDownloading]);

    useState(() => {
        if (!isUninitialized) {
            toast.error(
                "Please upload the data as per the provided template file."
            );
        }
    }, [isDownloadError]);

    return (
        <div className="row my-5 px-md-4">
            <div className="d-flex justify-content-between">
                <h4 className="fw-bold">Upload Data</h4>
                <div className="d-flex align-self-center align-items-center">
                    <button
                        type={"button"}
                        className={
                            "btn btn-secondary fw-semibold btn-sm px-3 py-2 d-flex align-items-center"
                        }
                        onClick={downloadTemplateFile}
                    >
                        <span className="me-2">Download Template</span>
                        {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#ffffff"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                            <path d="M7 11l5 5l5 -5" />
                            <path d="M12 4l0 12" />
                        </svg> */}
                    </button>
                </div>
            </div>
            <div className="d-flex flex-column my-4">
                <small className="fw-medium">Upload Text File</small>
                <div className="card home-top-card sm mt-2">
                    <div className="card-body">
                        <FileUpload />
                    </div>
                </div>
            </div>
            <div className="separator">OR</div>
            <div className="d-flex flex-column my-4">
                <small className="fw-medium">Add Question and Answers</small>
                <QnAComponent />
            </div>
        </div>
    );
};

export default UploadDataComponent;
