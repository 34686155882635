import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { head } from "lodash";
import { toast } from "react-toastify";
import { AuthFromLocalStorage } from "../context/auth-context";
import { BASE_URL } from "../utils/constants";

export const HTTP_STATUS = {
    FORBIDDEN: 403,
    UNAUTHORIZED: 401
};

export const DashboardAPI = createApi({
    reducerPath: "DashboardAPIs",
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL
    }),
    refetchOnReconnect: true,
    tagTypes: ["QnA"],
    endpoints: (builder) => ({
        getDashboardStats: builder.query({
            query: () => {
                return {
                    url: `/v1/organisations/analytics`,
                    // url: `/analytics.json`,
                    method: "GET",
                    headers: {
                        Authorization: AuthFromLocalStorage()
                    }
                };
            },
            transformResponse: (response) => {
                return response?.data || {};
            }
        }),
        getChatSessions: builder.query({
            query: ({ pageNumber, createdAtFirstItem = null }) => {
                let qp = `?pageNumber=${pageNumber || 1}&createdAtFirstItem=${
                    createdAtFirstItem || new Date().toISOString()
                }`;

                return {
                    url: `/v1/chat-sessions${qp}`,
                    // url: `/history.json${qp}`,
                    method: "GET",
                    headers: {
                        Authorization: AuthFromLocalStorage()
                    }
                };
            },
            transformResponse: (response) => {
                return response?.data || {};
            }
        }),
        getChatById: builder.query({
            query: (id) => {
                return {
                    url: `/v1/chat-messages?chatSessionId=${id}`,
                    // url: `/chatData.json?chatSessionId=${id}/`,
                    method: "GET",
                    headers: {
                        Authorization: AuthFromLocalStorage()
                    }
                };
            },
            transformResponse: (response) => {
                return response?.data || {};
            }
        }),
        uploadFile: builder.mutation({
            query: (data) => {
                return {
                    url: "/v1/organisation-contents/payload/file",
                    body: data,
                    method: "PUT",
                    headers: {
                        Authorization: AuthFromLocalStorage()
                    }
                };
            },
            invalidatesTags: ["QnA"]
        }),
        addQA: builder.mutation({
            query: (data) => {
                return {
                    url: `/v1/organisation-contents/payload/data`,
                    body: { organisationContentsList: data },
                    method: "PUT",
                    headers: {
                        Authorization: AuthFromLocalStorage()
                    }
                };
            },
            invalidatesTags: ["QnA"]
        }),
        fetchQA: builder.query({
            query: () => {
                return {
                    url: "/v1/organisation-contents/",
                    method: "GET",
                    headers: {
                        Authorization: AuthFromLocalStorage()
                    }
                };
            },
            transformResponse: (response) => {
                return response?.data || {};
            },
            providesTags: ["QnA"]
        }),
        fetchTemplateFile: builder.query({
            query: () => {
                return {
                    url: "/v1/organisation-contents/template/csv",
                    method: "GET",
                    responseHandler: async (response) => {
                        try {
                            const header = response.headers.get(
                                "Content-Disposition"
                            );
                            const parts = header?.split(";");
                            let filename =
                                parts && parts.length > 0
                                    ? parts[1].split("=")[1].replace(/\"/g, "")
                                    : null;

                            // window.location.assign(window.URL.createObjectURL(await response.blob())),
                            let a = document.createElement("a");
                            document.body.appendChild(a);
                            a.style = "display: none";
                            let blob = await response.blob(),
                                url = window.URL.createObjectURL(blob);

                            a.href = url;
                            a.download =
                                filename ||
                                "Organisation Contents Data Template.csv";
                            a.click();
                            window.URL.revokeObjectURL(url);
                        } catch (error) {
                            console.log(error);
                        }
                    },
                    cache: "no-cache",
                    headers: {
                        Authorization: AuthFromLocalStorage(),
                        "Content-Type": "text/csv; charset=utf-8"
                    }
                };
            },
            transformErrorResponse: async (error, data) => {
                toast.error(
                    error?.data?.error ||
                        "Error downloading file, try again"
                );
            },
            keepUnusedDataFor: 0
        })
    })
});

export const {
    useGetDashboardStatsQuery,
    useUploadFileMutation,
    useAddQAMutation,
    useGetChatByIdQuery,
    useGetChatSessionsQuery,
    useFetchQAQuery,
    useFetchTemplateFileQuery
} = DashboardAPI;
