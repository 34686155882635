import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import _ from "lodash";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top",
            display: false
        },
        title: {
            display: false,
            text: "Daily Interactions"
        }
    },
    maintainAspectRatio: true,
    layout: {
        padding: {
            left: 12,
            right: 12
        }
    },
    scales: {
        y: {
            border: { dash: [4, 4] } // for the grid lines
        },
        x: {
            display: true,
            grid: {
                display: false
            }
        }
    }
};

const labels = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
];

const BarChart = ({ data = [] }) => {
    const labels = _.times(7)
        .map((i) => {
            return new Date(
                new Date().getTime() - (i + 1) * 24 * 60 * 60 * 1000
            ).toLocaleString("en-IN", {
                month: "short",
                day: "2-digit"
            });
        })
        .reverse();
    return (
        <Bar
            options={options}
            data={{
                labels,
                datasets: [
                    {
                        barPercentage: 0.5,
                        barThickness: 24,
                        minBarLength: 2,
                        maxBarThickness: 24,
                        label: "Sessions",
                        data: [...data, Math.max(...data) + 10],
                        backgroundColor: "rgba(62,151,255,1)",
                        borderRadius: 6
                    }
                ]
            }}
        />
    );
};

export default BarChart;
