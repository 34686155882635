import { useRef, useState } from "react";
import { useAuthContext } from "../../context/auth-context";
import { toast } from "react-toastify";

const AuthComponent = () => {
    const authCtx = useAuthContext();
    const emailRef = useRef();
    const passwordRef = useRef();
    const [loading, setLoading] = useState(false);

    const handleFormSubmission = async (e) => {
        e.preventDefault();
        if (!emailRef.current.value || !passwordRef.current.value) {
            alert("Enter both email and password");
        }

        try {
            setLoading(true);
            // do certain operations and update the context
            await authCtx.doLogin({
                email: emailRef.current.value,
                password: passwordRef.current.value
            });
            toast.success("Successfully Logged In");
        } catch (error) {
            toast.error(
                error?.response?.data?.error ||
                    "Some error occurred while logging in, please try again"
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container-fluid auth-container">
            <div className="row">
                <div className="col-md-5 order-md-1 order-2 mt-5 mt-md-0">
                    <div className="d-flex flex-column h-100 w-100 align-items-center justify-content-center px-lg-5 px-md-4 px-sm-5 px-4 px-3">
                        <div className="d-flex flex-column align-items-center mb-5">
                            <div className="d-flex">
                                <img
                                    src={`/assets/logo.webp`}
                                    alt={"Aphelia Logo"}
                                    className={"img-fluid"}
                                    width={160}
                                />
                            </div>
                            <h4 className="mt-3">Sign In</h4>
                        </div>
                        <form
                            className="form mt-4 w-100"
                            onSubmit={handleFormSubmission}
                        >
                            <div className="form-group pb-3 border-bottom">
                                <input
                                    required
                                    ref={emailRef}
                                    className="form-control"
                                    type={"email"}
                                    placeholder="Email"
                                    name="email"
                                    disabled={loading}
                                />
                            </div>
                            {/* <div className="divider"/> */}
                            <div className="form-group my-3">
                                <input
                                    required
                                    ref={passwordRef}
                                    className="form-control"
                                    type={"password"}
                                    placeholder="Password"
                                    name="password"
                                    disabled={loading}
                                />
                            </div>
                            <div className="d-flex justify-content-end">
                                <button
                                    type={"button"}
                                    className="btn-link btn forgot-btn px-0"
                                >
                                    <small>Forgot Password?</small>
                                </button>
                            </div>
                            <div className="d-flex justify-content-end mt-3">
                                {/* d-flex */}
                                <div className="d-none fs-14 align-items-center">
                                    <span>Don't have an account? </span>
                                    <button
                                        type={"button"}
                                        className="btn-link btn p-0 ms-2 text-decoration-none"
                                    >
                                        <small>Signup</small>
                                    </button>
                                </div>
                                <button
                                    disabled={loading}
                                    className="btn btn-primary text-white px-4 py-2 signIn-btn"
                                    type={"submit"}
                                >
                                    {loading ? "Please wait.." : "Sign In"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div
                    className="col-md-7 order-md-2 order-1 p-0 auth-container__bg"
                    style={{ backgroundImage: "url(/assets/bg-image.jpeg)" }}
                >
                    <div className="d-flex h-inherit w-100 h-100 justify-content-center align-items-center">
                        <h1 className="text-light">Welcome to Aphelia!</h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthComponent;
