import { NavLink } from "react-router-dom";
import { useAuthContext } from "../context/auth-context";

const HeaderComponent = () => {
    const authCtx = useAuthContext();

    return (
        <header className="container-fluid sticky-top">
            <div className="container">
                <div className="row">
                    <div className="d-flex flex-column flex-sm-row justify-content-between px-4">
                        <div className="brand-logo">
                            <img
                                className={"img-fluid"}
                                src={"/assets/logo.webp"}
                                width={120}
                            />
                        </div>
                        <div className="d-flex mt-4 mt-sm-0 justify-content-center">
                            <ul className="list-unstyled d-flex align-items-center pe-md-2">
                                <li>
                                    <NavLink to={"/"}>Dashboard</NavLink>
                                </li>
                                <li>
                                    <NavLink to={"/manage"}>
                                        Manage Data
                                    </NavLink>
                                </li>
                                <li>
                                    <button
                                        className="btn p-0"
                                        onClick={() => {
                                            authCtx.logout();
                                        }}
                                    >
                                        Logout
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HeaderComponent;
