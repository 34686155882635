import _ from "lodash";
import { Fragment } from "react";
import { useGetChatByIdQuery } from "../../../services/api";

const ChatModal = ({ id, time, closeModal = () => {} }) => {
    const { data, isLoading, isError } = useGetChatByIdQuery(id);

    if (!id) {
        return <></>;
    }

    return (
        <Fragment>
            <div
                className={`modal fade show`}
                style={{ display: "block" }}
                id="chatSessionModal"
                tabIndex="-1"
                aria-labelledby="chatSessionModal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1
                                className="modal-title fs-5"
                                id="exampleModalLabel"
                            >
                                {time}
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeModal}
                            ></button>
                        </div>
                        <div className="modal-body">
                            {isLoading && "Loading.."}
                            {isError && (
                                <div
                                    className="alert alert-danger"
                                    role="alert"
                                >
                                    Some error occurred while loading the chat
                                    history, please refresh and try again.
                                </div>
                            )}
                            <div className="chatBot--messages">
                                <ul className="list-unstyled">
                                    {data &&
                                        data.chatMessagesList &&
                                        _.map(data.chatMessagesList, (item) => {
                                            return item.source === "USER" ? (
                                                <li
                                                    key={item.id}
                                                    className="user"
                                                >
                                                    <span>{item.content}</span>
                                                </li>
                                            ) : (
                                                <li key={item.id}>
                                                    <img
                                                        src={`/assets/logo.svg`}
                                                        className={"img-fluid"}
                                                        alt="Company Icon"
                                                    />
                                                    <span>{item.content}</span>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </Fragment>
    );
};

export default ChatModal;
