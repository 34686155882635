import { BrowserRouter, Route, Routes } from "react-router-dom";
import BaseComponent from "./pages";

const BaseRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<BaseComponent />} />
            </Routes>
        </BrowserRouter>
    );
};

export default BaseRoutes;
