const HomeStatsCard = ({ data }) => {
    return (
        <div className="col-md-3 col-sm-6 col-12 mb-3 mb-md-0">
            <div className="home-top-card card">
                <div className="card-body">
                    <div className="d-flex flex-column">
                        <span>{data.icon}</span>

                        <div className="d-flex flex-column my-7">
                            <div className="fw-semibold fs-1 text-gray-800 lh-1 ls-n2">
                                {data.count || "-"}
                            </div>
                            <div className="fw-medium fs-14 text-cs-gray-400 m-0">
                                {data.heading}
                            </div>
                        </div>
                        <div className="d-inline-flex">
                            <span
                                className={`badge badge-light-${
                                    data.percent && data.percent >= 0
                                        ? "success"
                                        : "danger"
                                } fs-12`}
                            >
                                {data.percent
                                    ? `${data.percent > 0 ? "+" : ""}${
                                          data.percent
                                      }%`
                                    : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeStatsCard;
