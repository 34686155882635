import _ from "lodash";
import { useEffect } from "react";
import { useAuthContext } from "../../context/auth-context";
import { useGetDashboardStatsQuery } from "../../services/api";
import ErrorComponent from "../Common/Error";
import ChatSessionsComponent from "./ChatSession";
import HomeStatsCard from "./Common/StatsCard";
import DoughnutChart from "./DoughnutChart";
import DashboardStatsComponent from "./Stats";

const Icons = {
    analytics: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#9e9e9e"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 4m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" />
            <path d="M7 20h10" />
            <path d="M9 16v4" />
            <path d="M15 16v4" />
            <path d="M9 12v-4" />
            <path d="M12 12v-1" />
            <path d="M15 12v-2" />
            <path d="M12 12v-1" />
        </svg>
    ),
    time: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#9e9e9e"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
            <path d="M4 13a8.094 8.094 0 0 0 3 5.24" />
            <path d="M11 15h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h2" />
            <path d="M17 15v2a1 1 0 0 0 1 1h1" />
            <path d="M20 15v6" />
        </svg>
    ),
    queries: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#9e9e9e"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
            <path d="M12 9h.01" />
            <path d="M11 12h1v4h1" />
        </svg>
    ),
    answer: (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#9e9e9e"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" />
            <path d="M8 13l3 -2l2 2l3 -2" />
        </svg>
    )
};

const DashboardHomeComponent = () => {
    const { data, isLoading, isError, error } = useGetDashboardStatsQuery({});
    // const { data, isLoading, isError, error } = {}
    const authCtx = useAuthContext();
    // const [topInformation, setTopInformation] = useState(data?.total || {})

    const topInformation = {
        sessions: {
            heading: "Total Sessions",
            count: data?.total?.sessions?.count || "0",
            icon: Icons.analytics,
            percent: data?.total?.sessions?.deltaPercentage
        },
        time: {
            heading: "Total Time (hrs)",
            count: data?.total?.time?.count || "0",
            icon: Icons.time,
            percent: data?.total?.time?.deltaPercentage
        },
        queries: {
            heading: "Total Queries",
            count: data?.total?.queries?.count || "0",
            icon: Icons.queries,
            percent: data?.total?.queries?.deltaPercentage
        },
        queriesAnswered: {
            heading: "Answered Queries",
            count: data?.total?.queriesAnswered?.count || "0",
            icon: Icons.answer,
            percent: data?.total?.queriesAnswered?.deltaPercentage
        }
    };
    const averageInfo = data?.average || {};
    const categoryChartInfo = data?.categoriesUsagePercentage || [];
    const dailySessionInfo = data?.dailySessions || [];

    useEffect(() => {
        authCtx.toggleLoader("Loading Data..", isLoading);
        if (!isLoading && data) {
            // setTopInformation(data?.total || {})
        }
    }, [isLoading]);

    if (isError) {
        return <ErrorComponent message={error?.data?.error || ""} />;
    }

    return (
        <div className="row my-5">
            <div className="row">
                {topInformation &&
                    _.map(topInformation, (data, index) => {
                        return <HomeStatsCard key={index} data={data} />;
                    })}
            </div>
            <DashboardStatsComponent
                averageInfo={averageInfo}
                dailySessionInfo={dailySessionInfo}
            />
            <div className="row mt-4">
                <div className="col-md-6 col-12 order-md-1 order-2 h-md-100 mb-3">
                    <ChatSessionsComponent />
                </div>
                <div className="col-md-6 col-12 order-md-2 order-1 mb-3">
                    <div className="card home-top-card w-100 h-100">
                        <div className="card-body">
                            <div className="card-title fw-semibold">
                                Categorical Analysis
                            </div>
                            <div className="d-flex py-5 justify-content-center w-100 p-md-5 p-4">
                                <DoughnutChart data={categoryChartInfo || []} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardHomeComponent;
