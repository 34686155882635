import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ data = [] }) => {
    const labels = data.map((i) => i.category);
    const values = data.map((i) => i.percentage);

    return (
        <Doughnut
            data={{
                labels,
                datasets: [
                    {
                        label: " Category Share (%)",
                        data: values,
                        backgroundColor: [
                            "rgba(255, 99, 132, 1)",
                            "rgba(54, 162, 235, 1)",
                            "rgba(255, 206, 86, 1)",
                            "rgba(75, 192, 192, 1)"
                        ],
                        borderWidth: 1
                    }
                ]
            }}
        />
    );
};

export default DoughnutChart;
