import { Fragment, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import HeaderComponent from "../components/Header";
import DashboardHomeComponent from "../components/Dashboard/Home";
import ManageDataComponent from "../components/Dashboard/ManageData";
import UploadDataComponent from "../components/Dashboard/Upload";
import Loader from "../components/Common/Loader";

const DashboardIndex = () => {
    useEffect(() => {
        document.body.style.backgroundColor = "#f8f9fa";

        return () => {
            document.body.style.backgroundColor = "white";
        };
    }, []);
    return (
        <Fragment>
            <HeaderComponent />
            <div className="container dashboard-container">
                <Routes>
                    <Route path="*" element={<Navigate to={"/"} replace />} />
                    <Route path="/" element={<DashboardHomeComponent />} />
                    <Route
                        path="/manage/upload"
                        element={<UploadDataComponent />}
                    />
                    <Route path="/manage" element={<ManageDataComponent />} />
                </Routes>
            </div>
            <Loader />
        </Fragment>
    );
};

export default DashboardIndex;
