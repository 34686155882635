import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthContext } from "../../../context/auth-context";
import { useUploadFileMutation } from "../../../services/api";

const FileUpload = () => {
    const navigate = useNavigate();
    const authCtx = useAuthContext();
    const [UploadFile] = useUploadFileMutation();
    const dropRef = useRef(null);
    const fileRef = useRef(null);
    const [file, setFile] = useState(null);

    const handleFileClick = () => {
        fileRef.current.click();
    };

    const handleFileChange = (e) => {
        const fileUploaded = e.target.files[0];
        setFile(fileUploaded);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const { files } = e.dataTransfer;
        if (files && files.length) {
            setFile(files[0]);
        }
    };

    const handleFileUpload = async () => {
        try {
            authCtx.toggleLoader("Uploading..", true);
            let data = new FormData();
            data.append("organisation-contents", file);
            await UploadFile(data).unwrap();

            toast.success("File uploaded successfully");
            authCtx.toggleLoader("Uploading..", false);

            setFile(null);
            navigate("/manage");
            fileRef.current.files = null;
            fileRef.current.value = null;
        } catch (error) {
            toast.error("Please upload the data as per the provided template file.");
            authCtx.toggleLoader("", false);
        }
    };

    useEffect(() => {
        dropRef.current.addEventListener("dragover", handleDragOver);
        dropRef.current.addEventListener("drop", handleDrop);

        return () => {
            if (dropRef && dropRef.current) {
                dropRef.current.removeEventListener("dragover", handleDragOver);
                dropRef.current.removeEventListener("drop", handleDrop);
            }
        };
    }, []);

    return (
        <div
            ref={dropRef}
            className="d-flex justify-content-between align-items-center"
        >
            <div className="d-flex align-items-center">
                <div className="d-block">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#9e9e9e"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1" />
                        <path d="M9 15l3 -3l3 3" />
                        <path d="M12 12l0 9" />
                    </svg>
                </div>
                <div className="d-flex flex-column ms-3">
                    <div className="fw-medium">Drag and drop files here</div>
                    <small className="text-muted fs-12 mt-1">
                        Limit 200mb per file • TEXT
                    </small>
                </div>
            </div>
            {file && file.name ? (
                <div className="d-flex align-items-center">
                    <span>{file.name}</span>
                    <button
                        className="btn btn-transparent btn-sm px-3"
                        onClick={() => {
                            setFile(null);
                            fileRef.current.files = null;
                            fileRef.current.value = null;
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#9e9e9e"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M18 6l-12 12" />
                            <path d="M6 6l12 12" />
                        </svg>
                    </button>
                    <button
                        className="btn btn-primary text-white fw-semibold btn-sm px-3 py-2"
                        onClick={handleFileUpload}
                    >
                        Upload
                    </button>
                </div>
            ) : (
                <button
                    className="btn btn-primary text-white fw-semibold btn-sm py-2 px-3"
                    onClick={handleFileClick}
                >
                    Browse files
                </button>
            )}
            <input
                hidden
                ref={fileRef}
                name={"file"}
                accept=".csv"
                type={"file"}
                onChange={handleFileChange}
            />
        </div>
    );
};

export default FileUpload;
