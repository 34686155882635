import { useAuthContext } from "../../context/auth-context";

export default function Loader() {
    const authCtx = useAuthContext();

    if (!authCtx.loader.show) {
        return <></>;
    }
    return (
        <div className="loader-wrap">
            <div className="d-flex flex-column w-100 h-100 align-items-center justify-content-center">
                <div className="loader-wrap--loading"></div>
                <div className="mt-2">{authCtx.loader.text || "Loading.."}</div>
            </div>
        </div>
    );
}
