import { createContext, useContext, useEffect, useState } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../utils/constants";

const AuthContext = createContext({
    userInfo: {},
    isLoggedIn: false,
    updateLoggedIn: () => {},
    logout: () => {},
    loader: {
        show: false,
        text: "Loading.."
    },
    toggleLoader: (text, show) => {},
    doLogin: async (creds = { email: "", password: "" }) => {}
});

const tokenName = "aphelia-db-token";

export const AuthFromLocalStorage = () => {
    const token = localStorage.getItem(tokenName);
    return token || null;
};

export const AuthContextProvider = ({ children }) => {
    const [userObject, setUserObject] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loaderInfo, setLoaderInfo] = useState({
        show: false,
        text: "Loading.."
    });

    useEffect(() => {
        Login()
            .then()
            .catch((error) => {
                toast.error(
                    error?.data || "Some error occurred while logging in"
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const UpdateLoggedIn = ({ authToken, organisation = {} }) => {
        if (!authToken) {
            setUserObject({});
            setIsLoggedIn(false);
            localStorage.removeItem(tokenName);
            return;
        }
        setUserObject(organisation);
        setIsLoggedIn(true);
        const token = AuthFromLocalStorage();
        localStorage.setItem(tokenName, authToken || token);
    };

    const ToggleLoader = (text, show = false) => {
        setLoaderInfo({
            show,
            text
        });
    };

    const Login = async (creds = {}) => {
        const { email, password } = creds;
        if (!email || !password) {
            UpdateLoggedIn({ authToken: AuthFromLocalStorage() });
            return;
        }
        const response = await Axios.post(
            `${BASE_URL}/v1/organisations/login`,
            {
                email,
                password
            }
        );
        UpdateLoggedIn(response.data.data);
    };

    return (
        <AuthContext.Provider
            value={{
                userInfo: userObject,
                isLoggedIn: isLoggedIn,
                updateLoggedIn: (...params) => UpdateLoggedIn(...params),
                logout: () => {
                    setUserObject({});
                    setIsLoggedIn(false);
                    localStorage.removeItem(tokenName);
                },
                loader: loaderInfo,
                toggleLoader: ToggleLoader,
                doLogin: Login
            }}
        >
            {!isLoading ? children : "Loading.."}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);
